<template>
    <section id="description-list-alignment">
        <b-row class="match-height">
            <b-col cols="4" style="overflow-y: auto;max-height: 400px;">
                <b-card>
                    <b-card-title>{{ $t("Archives") }} </b-card-title>
                    <b-row>
                        <b-col cols="12">
                            <b-card>
                                <app-collapse accordion type="margin" v-for="item in issues" :key="item.id">
                                    <app-collapse-item :title=item.begin>
                                        <b-card-text v-for="item in item.issues" :key="item.id">
                                            <b-link @click="getA(item.id)">
                                                {{ $t("volumn") }} : {{ item.title }}
                                            </b-link>
                                        </b-card-text>
                                    </app-collapse-item>
                                </app-collapse>
                            </b-card>
                        </b-col>

                    </b-row>
                </b-card>
            </b-col>
            <b-col md="8" v-if="articles.length > 0" >
                <b-row>
                    <b-col md="6" v-for="item in articles" :key="item.id">
                        <b-card border-variant="primary" :title=item.title bg-variant="transparent" class="shadow-none">
                    <b-row style="text-align: center;">
                        <b-col md="12">
                            <b-card-text>
                                {{ item.authors }}
                            </b-card-text>
                        </b-col>
                        <b-col md="6">
                            {{ $t("Year") }} : {{ item.year }}
                        </b-col>
                        <b-col md="6">
                            {{ $t("volumn") }} : {{ item.issue.title }}
                        </b-col>
                        <b-col md="12">
                            <b-link :to="{ name: 'details', params: { id: item.id } }">
                                <b-card-text>
                                    <span><feather-icon icon="EyeIcon" class="mr-50" /></span>{{ $t("Details") }}
                                </b-card-text>
                            </b-link>
                        </b-col>
                    </b-row>
                </b-card>
                    </b-col>
                </b-row>
                
            </b-col>

        </b-row>
    </section>
</template>
  
<script>
import {
    BRow, BCol, BCard, BCardTitle, BButton, BCardText, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { filesUrl, Url } from "@/main.js";
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import {
    BFormRadioGroup, BFormRadio, BFormGroup, BLink
} from 'bootstrap-vue'
import { codeCollapseTypes } from './code'
export default {
    components: {
        BRow,
        BCol, Url,
        BCard, BButton,
        BCardTitle, BLink,
        BCardText,
        BCardHeader, AppCollapseItem, AppCollapse, BCardCode,
        BCardBody, BFormRadioGroup, BFormRadio, BFormGroup
    },
    data() {
        return {
            journals: [],
            issues: [],
            articles: [],
            perPage: 12,
            currentPage: 0,
        }
    },
    mounted() {
        this.getIssue()
    },
    methods: {
        async getIssue() {
            await fetch(
                `${Url}archive/issue/${this.$route.params.id}`,
                {
                    headers: {
                        "Accept-Language": this.$i18n.locale,
                    },
                }
            )
                .then(response => response.json())
                .then(data => {
                    this.issues = [];
                    this.issues = data.items;
                });
        },
        getImage(path) {
            return `${filesUrl}/${path}`;
        },
        async getA(id) {
            await fetch(
                `${Url}articles?issue_id=${id}&take=${1000}&skip=0`,
                {
                    headers: {
                        "Accept-Language": this.$i18n.locale,
                    },
                }
            )
                .then(response => response.json())
                .then(data => {
                    this.articles = [];
                    this.articles = data.items;
                    console.log(this.articles)
                });
        }
    },
}
</script>
